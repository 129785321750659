import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './Loading.module.scss';

class Loading extends Component {
  render() {
    const { label, text = 'Loading', inline = false } = this.props;
    return (
      <div className={classNames(styles.loading, { [styles.loadingContainer]: !inline })}>
        <div className={classNames({ [styles.loadingBox]: !inline, [styles.inline]: inline })}>
          <div className={styles.ldsRipple}>
            <div />
            <div />
          </div>
          <div className={styles.loadingText}>{text}</div>
          {label && <div className={styles.loadingLabel}>{label}</div>}
        </div>
      </div>
    );
  }
}

export default Loading;
