const initialState = {
  drawerItem: null,
  inputDeviceId: '',
  inputDeviceStatus: null,
  isDebugMode: false,
  isFullScreen: false,
  isMicrophoneActive: false,
  isVerizonBT: false,
  isPaused: false,
  isPlaying: false,
  isShelfActive: false,
  isSkippingBack: false,
  notifications: [],
  outputDeviceId: '',
  videoSize: { width: 0, height: 0 },
  volumeLevel: 100
};

export default initialState;
