//import { createStore } from 'redux';
 import { createStore, applyMiddleware, compose } from 'redux';
import { connectRouter } from 'connected-react-router';
import thunk from 'redux-thunk';
// import { reactReduxFirebase } from 'react-redux-firebase';
import history from './history';
import rootReducer from './modules';
import firebase from './firebase';

const initialState = {};

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
  // enableClaims: true // Get custom claims along with the profile
}

const enhancers = [
  // reactReduxFirebase(firebase, rrfConfig),
];

// // , thunk.withExtraArgument(getFirebase)
// const middleware = [routerMiddleware(history)];

if (process.env.NODE_ENV === 'development') {
  const { devToolsExtension } = window;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(thunk),
  ...enhancers,
);

const store = createStore(
  connectRouter(history)(rootReducer),
  initialState,
  composedEnhancers,
);
export default store;
export { rrfConfig, store, firebase }
