import firebase from 'firebase/app';
import { initializeApp, auth as fbauth, database } from 'firebase';
import firebaseConfig from '../config/firebase';


const fbConfig = firebaseConfig;

// Initialize firebase instance
initializeApp(fbConfig);

export const provider = new fbauth.GoogleAuthProvider();
export const auth = fbauth();
export const ref = database().ref();

export default firebase;
