import React, { Component } from 'react';
import styles from './Home.module.scss';

class Home extends Component {

  render() {
    return (
      <div className={styles.wrapper}>
        <h1>Home</h1>
        <div>Some copy goes here</div>
      </div>
    );
  }
}


export default Home;
