import React, { Component } from 'react';
import classnames from 'classnames';
import styles from './Checkbox.module.scss';

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.isChecked ? props.isChecked : false,
    };
  }

  handleChange() {
    const { onChange, label } = this.props;
    const newValue = !this.state.checked;
    this.setState(
      {
        checked: newValue,
      },
      onChange(label, newValue)
    );
  }
  render() {
    const { label = 'checkbox', isChecked, onChange, disabled, title, className, size, theme } = this.props;

    const componentProps = {
      className: classnames(
        styles.input,
        styles[`theme--${theme}`],
        styles[`size--${size}`],
        disabled && styles.disabled,
        className
      ),
    };

    return (
      <div className={classnames(styles.checkboxContainer, componentProps.className)}>
        <label className={styles.checkLabel} title={title}>
          <input
            className={styles.checkbox}
            type="checkbox"
            value={label}
            checked={isChecked}
            onChange={onChange}
            disabled={disabled}
          />
          {label}
        </label>
      </div>
    );
  }
}

export default Checkbox;
