const userStorage = window.localStorage;
const firstRun = userStorage.getItem('om:firstrun') === 'true';

const initialState = {
  isActive: false,
  fullDuration: 300,
  activeDuration: 300,
  firstRun: firstRun,
};

export default initialState;
