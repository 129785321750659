export const UPDATE_NOTIFICATIONS = 'ui/UPDATE_NOTIFICATIONS';

export const VIDEO_SIZE_SET = 'ui/VIDEO_SIZE_SET';
export const VIDEO_SIZE_UPDATE = 'ui/VIDEO_SIZE_SET';

export const UPDATE_MICROPHONE = 'ui/UPDATE_MICROPHONE';
export const UPDATE_INPUTDEVICE_STATUS = 'ui/UPDATE_INPUTDEVICE_STATUS';
export const UPDATE_INPUTDEVICE = 'ui/UPDATE_INPUTDEVICE';
export const UPDATE_OUTPUTDEVICE = 'ui/UPDATE_OUTPUTDEVICE';

export const UPDATE_FULLSCREEN = 'ui/UPDATE_FULLSCREEN';

export const UPDATE_DEBUGMODE = 'ui/UPDATE_DEBUGMODE';

// TODO: rename isPlaying to isMediascapeStarted
export const UPDATE_PLAYING = 'ui/UPDATE_PLAYING';
export const UPDATE_PAUSE = 'ui/UPDATE_PAUSE';
export const UPDATE_SKIP_BACK = 'ui/UPDATE_SKIP_BACK';

export const UPDATE_DRAWER = 'ui/UPDATE_DRAWER';

export const UPDATE_SHELF_STATUS = 'ui/UPDATE_SHELF_STATUS';

export const UPDATE_AUDIO_VOLUME = 'ui/UPDATE_AUDIO_VOLUME';

export const RESETTING_UI = 'ui/RESETTING_UI';

export const RATE_MEDIASCAPE = 'ui/RATE_MEDIASCAPE';
