import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { Home, Admin } from './pages';
import { Header, Login } from './components';
import styles from './App.module.scss';


class App extends Component {
  render() {
    const { auth, profile } = this.props;
    const isAuth = auth.isLoaded && !auth.isEmpty;

    console.log(profile);
    console.log(isAuth)

    return (
      <Router>
        <div className={styles.App}>
          <Header />
          <div className={styles.pages}>
            {isAuth && (
              <main>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/admin" component={Admin} />
                  {/* <Route component={NoMatch} /> */}
                </Switch>
              </main>
            )}
            {!isAuth && (
              <main>
                <Switch>
                  {/* <Route exact path="/invite" component={InviteRequest} /> */}
                  {/* <Route component={Login} /> */}
                  <Login></Login>
                </Switch>
              </main>
            )}
          </div>
        </div>
      </Router>
    );
  }
}

const enhance = connect(
  // Map redux state to component props
  ({ firebase: { auth, profile } }) => ({
    auth,
    profile,
  }),
);

export default enhance(App);
