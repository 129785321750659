import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Input.module.scss';

class Input extends Component {
  constructor(props) {
    super(props);

    this.inputField = React.createRef();
  }

  componentDidMount() {
    const { isFocus } = this.props;
    if (isFocus) {
      this.inputField.current.focus();
    }
  }

  componentDidUpdate() {
    const { isFocus } = this.props;
    if (isFocus) {
      this.inputField.current.focus();
    }
  }

  handKeyDown(key) {
    const { onKeyDown, onEnter } = this.props;

    if (onKeyDown) {
      onKeyDown(key);
    }

    if (onEnter) {
      if (key.keyCode === 13 || key.keyCode === 27) {
        onEnter();
      }
    }
  }

  handleClear() {
    this.props.onClear();
  }

  clearIcon() {
    const { clear } = this.props;

    return (
      <span className={styles.clear} onClick={() => this.handleClear()}>
        <i className={classnames('fa', clear)} />
      </span>
    );
  }

  render() {
    const {
      clear,
      value = '',
      onChange,
      onBlur,
      disabled = false,
      label,
      placeholder,
      size = 'normal',
      theme = 'standard',
      className = '',
      type = 'text',
      icon,
      required,
      min,
      max,
      step,
      maxLength,
    } = this.props;

    const componentProps = {
      className: classnames(
        styles.input,
        styles[`theme--${theme}`],
        styles[`size--${size}`],
        disabled && styles.disabled,
        className,
      ),
    };

    let ph = placeholder;
    if (theme === 'material' && !!label && !placeholder) {
      ph = label;
    }
    return (
      <div className={classnames(styles.inputControl, styles.group, componentProps.className)}>
        {theme !== 'material' && !!label && <label>{label}</label>}
        <input
          className={styles.customInput}
          ref={this.inputField}
          type={type}
          min={min}
          max={max}
          step={step}
          required={required}
          value={value}
          onChange={onChange}
          onKeyDown={e => this.handKeyDown(e)}
          onBlur={onBlur}
          disabled={disabled}
          maxLength={maxLength}
          placeholder={ph}
        />
        {size === 'keyword' && !!clear && !!value && this.clearIcon()}
        {icon && (
          <span className={styles.icon}>
            <i className={classnames('fa', icon)} aria-hidden="true" />
          </span>
        )}
        <span className={styles.highlight} />
        {theme === 'material' && <span className={styles.bar} />}

        {theme === 'material' && (!!value && !!label && <label>{label}</label>)}
      </div>
    );
  }
}

export default Input;

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClear: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
  onEnter: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal', 'keyword']),
  theme: PropTypes.oneOf(['material', 'standard', 'dark']),
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'color',
    'date',
    'email',
    'image',
    'month',
    'number',
    'password',
    'search',
    'tel',
    'time',
    'url',
    'week',
    'hidden',
  ]),
  clear: PropTypes.string,
  icon: PropTypes.string,
  required: PropTypes.bool,
  min: PropTypes.number || PropTypes.string || PropTypes.date,
  max: PropTypes.number || PropTypes.string || PropTypes.date,
  step: PropTypes.number,
  maxLength: PropTypes.number,
  isFocus: PropTypes.bool,
};

// Specifies the default values for props:
Input.defaultProps = {
  clear: '',
  value: '',
  disabled: false,
  size: 'normal',
  theme: 'standard',
  className: '',
  type: 'text',
  isFocus: false,
};
