import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firebaseConnect, isEmpty } from 'react-redux-firebase';
import classNames from 'classnames';
import { Icon } from '../index';
import IconList from '../../assets/icons/IconList';

import styles from './Header.module.scss';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hamburgerClick: false,
    };

    this.iconTypes = IconList.iconTypes;
  }

  handleCloseRefresh() {
    this.setState({ showMessage: false });
    window.location.reload(true);
  }

  handleNotifyShow(packageVersion) {
    const { firebase, auth } = this.props;

    firebase.update(`users/${auth.uid}/notifications/packageStatus/${packageVersion}`, {
      status: 'ready',
      bookTitle: null,
      soundscapeTitle: null,
      projectTitle: null,
      mediascapeTitle: null,
      progressMax: null,
    });
  }

  handleNavigation(path) {
    this.setState({ hamburgerClick: false });
    if (path) {
      this.props.history.push(path);
    }
  }

  resetNav() {
    this.setState({ hamburgerClick: false });
  }

  handleLogOut() {
    this.resetNav();
    this.props.firebase.logout();
    
  }

  render() {
    const { profile, auth } = this.props;

    const forAdmin = profile && profile.type === 'admin';

    return (
      <header className={styles.Header}>
        <div className={styles.HeaderLogo}></div>

        {!isEmpty(auth) && (
          <nav
            role="navigation"
            className={classNames(styles.topNavigation, {
              [styles.activeBurger]: this.state.hamburgerClick,
            })}
          >
            <ul>
              <li className={styles.icon}>
                <Link
                  to="/"
                  onClick={() => this.setState({ hamburgerClick: !this.state.hamburgerClick })}
                >
                  {this.state.hamburgerClick ? (
                    <i
                      className={classNames(styles.userIcon, 'fa', 'fa-times')}
                      aria-hidden="true"
                    />
                  ) : (
                    <i
                      className={classNames(styles.userIcon, 'fa', 'fa-bars')}
                      aria-hidden="true"
                    />
                  )}
                </Link>
              </li>
              {profile && forAdmin && (
                <li className={styles.primary} onClick={() => this.handleNavigation('/project')}>
                  Projects
                </li>
              )}
              {profile && forAdmin && (
                <li className={styles.primary} onClick={() => this.handleNavigation(null)}>
                  Admin
                  {/* <ul className={styles.dropdown}>
                    {adminMenu.map((item, index) => (
                      <li key={index} onClick={() => this.handleNavigation(item.path)}>
                        {item.label}
                      </li>
                    ))}
                  </ul> */}
                </li>
              )}

              <li onClick={() => this.handleNavigation(null)}>
                <Icon iconName={this.iconTypes.USER} className={styles.userIcon} />

                <ul className={styles.dropdown}>
                  <li onClick={() => this.handleNavigation('/login')}>View profile</li>
                  <li onClick={() => this.handleLogOut()}>
                    Log Out
                    <br />
                    <span className={styles.userEmail}>{auth.email}</span>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        )}
      </header>
    );
  }
}


const enhance = compose(
  firebaseConnect(),
  connect(
  // Map redux state to component props
  ({ firebase: { auth, profile } }) => ({
    auth,
    profile,
  }),
));

export default enhance(Header);
