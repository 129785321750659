import React, { Component } from 'react';
import classnames from 'classnames';
import styles from './Toggle.module.scss';

class Toggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.isChecked ? props.isChecked : false,
    };
  }

  render() {
    const { label = 'checkbox', isChecked, onChange, disabled, className, size, theme } = this.props;

    const componentProps = {
      className: classnames(styles.input, styles[`theme--${theme}`], styles[`size--${size}`], disabled && styles.disabled, className),
    };

    return (
      <div className={classnames(styles.toggleContainer, componentProps.className)}>
        <label className={styles.switch}>
          <input type="checkbox" checked={isChecked} onChange={onChange} disabled={disabled} />
          <span className={classnames(styles.slider, styles.round)} />
          {/* {label} */}
        </label>
      </div>
    );
  }
}

export default Toggle;
