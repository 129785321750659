import React, { Component } from 'react';
import classNames from 'classnames';
import { Icon } from '../index';
import styles from './Modal.module.scss';

class Modal extends Component {
  render() {
    const {
      title = '',
      actions,
      open = false,
      onRequestClose,
      width = 'normal',
      theme = 'normal',
      children,
      className,
    } = this.props;

    const customStyles = {
      className: classNames(
        styles.modalContent,
        styles[`theme--${theme}`],
        styles[`width--${width}`],
        className,
      ),
    };

    const areActionsArray = Array.isArray(actions);

    if (open) {
      return (
        <div id="myModal" className={styles.modal}>
          <div {...customStyles}>
            <div className={styles.modalHeader}>
              {onRequestClose && (
                <span className={styles.close} onClick={onRequestClose}>
                  <Icon iconName="close" className={styles.closeIcon} />
                </span>
              )}
              <div className={styles.modalLabel}>{title}</div>
            </div>
            <div className={styles.modalBody}>{children}</div>
            <div className={styles.modalFooter}>
              {areActionsArray &&
                actions.map((action, index) => {
                  let tempAction = { ...action };
                  tempAction.key = index;
                  return tempAction;
                })}
              {!areActionsArray && actions && <div>{actions}</div>}
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default Modal;
