import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  Alert,
  Back,
  Book,
  Check,
  Close,
  CopyTo,
  Delete,
  DropDown,
  Duplicate,
  Filter,
  Kabob,
  Lock,
  Menu,
  Plus,
  Rename,
  Sort,
  Star,
  Trash,
  Upload,
  User,
  Video,
} from '../../assets/icons';
import IconList from '../../assets/icons/IconList';
import styles from './Icon.module.scss';

class Icon extends React.Component {
  constructor(props) {
    super(props);

    this.iconTypes = IconList.iconTypes;
  }

  fetchIcon = iconName => {
    switch (iconName) {
      case this.iconTypes.ALERT:
        return <Alert />;
      case this.iconTypes.BACK:
        return <Back />;
      case this.iconTypes.BOOK:
        return <Book />;
      case this.iconTypes.CHECK:
        return <Check />;
      case this.iconTypes.CLOSE:
        return <Close />;
      case this.iconTypes.COPYTO:
        return <CopyTo />;
      case this.iconTypes.DELETE:
        return <Delete />;
      case this.iconTypes.DROPDOWN:
        return <DropDown />;
      case this.iconTypes.DUPLICATE:
        return <Duplicate />;
      case this.iconTypes.FILTER:
        return <Filter />;
      case this.iconTypes.KABOB:
        return <Kabob />;
      case this.iconTypes.LOCK:
        return <Lock />;
      case this.iconTypes.MENU:
        return <Menu />;
      case this.iconTypes.PLUS:
        return <Plus />;
      case this.iconTypes.RENAME:
        return <Rename />;
      case this.iconTypes.SCENE_VIDEO:
        return <Video />;
      case this.iconTypes.SORT:
        return <Sort />;
      case this.iconTypes.STAR:
        return <Star />;
      case this.iconTypes.TRASH:
        return <Trash />;
      case this.iconTypes.UPLOAD:
        return <Upload />;
      case this.iconTypes.VIDEO:
        return <Video />;
      case this.iconTypes.USER:
        return <User />;
      case this.iconTypes.CORPUS_VIDEO:
        return (
          <span role="img" aria-label="angry" title="this media type has been deprecated">
            💢
          </span>
        );
      default:
        return (
          <span role="img" aria-label="wrong" title="icon not found">
            😭
          </span>
        );
    }
  };
  render() {
    const { iconName, className } = this.props;
    return <div className={classnames(styles.icon, className)}>{this.fetchIcon(iconName)}</div>;
  }
}

export default Icon;

Icon.propTypes = {
  className: PropTypes.string,
  iconName: PropTypes.string,
};
