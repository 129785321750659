import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';

class Button extends React.Component {
  render() {
    const {
      children,
      className,
      disabled,
      raised,
      inverse,
      wide,
      size,
      theme,
      onClick,
      color,
      onMouseDown,
      title,
    } = this.props;
    let style = {};
    if (color) {
      style = { backgroundColor: color };
    }
    return (
      <button
        className={classnames(
          styles.button,
          styles[`theme--${theme}`],
          styles[`size--${size}`],
          raised && styles.raised,
          inverse && styles.inverse,
          wide && styles.wide,
          className,
        )}
        disabled={disabled}
        onClick={onClick}
        onMouseDown={onMouseDown}
        style={style}
        title={title}
      >
        <span className={styles.children}>{children}</span>
      </button>
    );
  }
}

export default Button;

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  raised: PropTypes.bool,
  href: PropTypes.string,
  inverse: PropTypes.bool,
  wide: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal', 'medium', 'large', 'tiny']),
  theme: PropTypes.oneOf([
    'primary',
    'success',
    'warning',
    'danger',
    'dark',
    'link',
    'light',
    'dark',
    'trackButton',
  ]),
};

// Specifies the default values for props:
Button.defaultProps = {
  disabled: false,
  inverse: false,
  wide: false,
  size: 'normal',
  theme: 'primary',
};
