class IconList {
  static get iconTypes() {
    const iconTypes = {
      ALERT: 'alert',
      BACK: 'back',
      BOOK: 'book',
      CHECK: 'check',
      CLOSE: 'close',
      COPYTO: 'copyto',
      DELETE: 'delete',
      DROPDOWN: 'dropdown',
      DUPLICATE: 'duplicate',
      FILTER: 'filter',
      KABOB: 'kabob',
      LOCK: 'lock',
      MENU: 'menu',
      PLUS: 'plus',
      RENAME: 'rename',
      SORT: 'sort',
      STAR: 'star',
      TRASH: 'trash',
      UPLOAD: 'upload',
      USER: 'user',
      SCENE_VIDEO: 'scene_video',
      CORPUS_VIDEO: 'corpus_video',
    };
    return iconTypes;
  }
}
export default IconList;
