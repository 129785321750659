import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Button, Input, Loading, Checkbox, Modal, Icon } from '../../components';
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import PPConstants from '../../config/constants';
import styles from './Login.module.scss';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetPassword: false,
      resetComplete: false,
      validEmail: false,
      tooManyAttempts: false,
      formEmail: '',
      resetEmail: '',
      formPass: '',
      message: '',
      resetErr: '',
      accountError: '',
      error: '',
      UserId: '',
      persistence: true,
    };
    this.userStorage = window.localStorage;
    this.loginMessages = PPConstants.loginMessage;
    this.loginAttemptLimit = PPConstants.loginAttemptLimit;
    const existingAttempts = parseInt(this.userStorage.getItem(`podpop:verify`));

    this.loginAttempts = existingAttempts ? existingAttempts : 100;
  }

  getFriendlyErr = (code, message) => {
    console.log('login err', code, message);

    let displayErr = this.loginMessages[code]
      ? this.loginMessages[code]
      : { message, type: 'password' };

    return displayErr;
  };
  emailIsValid = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  handleEmail(email) {
    const currentValid = this.emailIsValid(email);
    this.setState({
      formEmail: email,
      resetEmail: email,
      validEmail: currentValid,
    });
  }

  handleEmailReset() {
    const { firebase } = this.props;
    const { resetEmail } = this.state;

    if (resetEmail !== null) {
      firebase
        .auth()
        .sendPasswordResetEmail(resetEmail)
        .then(() => {
          this.setState({
            resetComplete: true,
            resetPassword: false,
            message: '',
            resetErr: '',
            accountError: '',
          });
        })
        .catch(error => {
          console.log(error);
          const errMess = this.getFriendlyErr(error.code, error.message);
          this.setState({ resetErr: errMess.message });
        });
    }
  }

  resetPasswordModal() {
    const { resetEmail, resetPassword, validEmail, resetErr } = this.state;

    const actions = [
      <Button onClick={() => this.handleEmailReset()} disabled={!validEmail}>
        Submit
      </Button>,
    ];

    return (
      <Modal
        title="Reset password"
        open={resetPassword}
        onRequestClose={() =>
          this.setState({
            resetPassword: false,
            message: '',
            resetErr: '',
            accountError: '',
          })
        }
        width="normal"
        actions={actions}
      >
        <div className={styles.resetModal}>
          <div className={styles.helpResetText}>
            Enter your podpop account email and we’ll send you instructions to reset your
            password.
          </div>
          <Input
            type="email"
            label="EMAIL"
            value={resetEmail}
            onChange={email => this.handleEmail(email.target.value)}
          />
          {resetErr && (
            <div className={styles.errorMessage}>
              <Icon iconName="alert" className={styles.alert} />
              {resetErr}
            </div>
          )}
        </div>
      </Modal>
    );
  }

  tooManyLoginAttempts() {
    const { tooManyAttempts } = this.state;

    const openEmail = () => {
      window.location.href =
        'mailto:support@podpop.co?subject=podpop Studio login support';
    };
    const actions = [
      <Button onClick={() => openEmail()}>Contact us</Button>,
      <Button onClick={() => this.setState({ resetPassword: true, tooManyAttempts: false })}>
        Reset password
      </Button>,
    ];

    const displayTitle = 'Having trouble?';
    return (
      <Modal
        title={displayTitle}
        open={tooManyAttempts}
        onRequestClose={() => this.setState({ tooManyAttempts: false })}
        width="normal"
        actions={actions}
      >
        <div>
          <div className={styles.helpResetText}>
            You’ve made too many unsuccessful login attempts. If you need help, you can contact us
            or reset your password.
          </div>
        </div>
      </Modal>
    );
  }

  resetCompleteModal() {
    const { resetEmail, resetComplete, message } = this.state;
    const actions = [<Button onClick={() => this.setState({ resetComplete: false })}>OK</Button>];

    const displayTitle = message ? 'Unable to reset password' : 'Password reset request sent';
    return (
      <Modal
        title={displayTitle}
        open={resetComplete}
        onRequestClose={() => this.setState({ resetComplete: false })}
        width="normal"
        actions={actions}
      >
        <div>
          {message && <div className={styles.helpResetText}>{message}</div>}
          {!message && (
            <div className={styles.helpResetText}>
              An email has been sent to {resetEmail} with instructions to reset your password.
            </div>
          )}
        </div>
      </Modal>
    );
  }

  forgotPassword() {
    this.setState({ resetPassword: true });
  }

  logout() {
    this.props.firebase.logout();
  }

  displayErrMessage = (code, message) => {
    let displayErr = this.getFriendlyErr(code, message);

    if (displayErr.type === 'account') {
      this.setState({ accountError: displayErr.message });
    } else {
      this.setState({ error: displayErr.message });
    }
  };

  login() {
    const { firebase } = this.props;
    const { formEmail, formPass, persistence } = this.state;

    const _this = this;

    // see https://firebase.google.com/docs/auth/web/auth-state-persistence
    const persistenceType = persistence
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION;
    firebase
      .auth()
      .setPersistence(persistenceType)
      .then(function () {
        _this.setState({ accountError: '', error: '' });
        _this.userStorage.removeItem(`podpop:verify`);
        firebase.analytics().logEvent('login', { email: formEmail });
        return firebase.auth().signInWithEmailAndPassword(formEmail, formPass);
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        _this.loginAttempts++;
        // we are adding 100 and converting the value to hex for the count of login attempts in order to obfuscate it
        const obscure = `0x${_this.loginAttempts.toString(16)}`;
        _this.userStorage.setItem(`podpop:verify`, obscure);

        if (_this.loginAttempts - 100 >= _this.loginAttemptLimit) {
          _this.setState({ tooManyAttempts: true });
        }
        // console.log(errorCode, errorMessage);
        firebase
          .analytics()
          .logEvent('login_failed', { email: formEmail, errorCode, errorMessage });
        _this.displayErrMessage(errorCode, errorMessage);
      });
  }

  componentDidMount() { }

  handleKey(e) {
    const { formPass, validEmail } = this.state;

    const disableLogin = !validEmail || !formPass;
    if (!disableLogin && e.keyCode === 13) {
      this.login();
    }
  }

  render() {
    const { formEmail, formPass, persistence, validEmail } = this.state;

    let loginHelpText = validEmail ? 'login' : 'email address is invalid';
    if (!formPass) {
      loginHelpText = 'password is required to log in';
    }

    const disableLogin = !validEmail || !formPass;
    return (
      <div className={styles.loginPage}>
        {!isLoaded(this.props.auth) && <Loading />}
        {isLoaded(this.props.auth) && isEmpty(this.props.auth) && (
          <div className={styles.loginbox}>
            <div className={styles.loginItems}>
              <div className={styles.loginHeader}>
                <div>Log in to begin!</div>
              </div>
              <div className={styles.inputBlock}>
                <div className={classNames(styles.inputs, styles.username)}>
                  <Input
                    id="formEmail"
                    name="formEmail"
                    type="email"
                    label="EMAIL"
                    value={formEmail}
                    onChange={email => this.handleEmail(email.target.value)}
                  />
                </div>
                {this.state.accountError !== '' && (
                  <div className={styles.errorMessage}>
                    <Icon iconName="alert" className={styles.alert} />
                    {this.state.accountError}
                  </div>
                )}
                <div className={classNames(styles.inputs, styles.password)}>
                  <Input
                    id="formPass"
                    name="formPass"
                    type="password"
                    label="PASSWORD"
                    value={formPass}
                    onChange={password => this.setState({ formPass: password.target.value })}
                    onKeyDown={e => this.handleKey(e)}
                  />
                </div>
              </div>
              {this.state.error !== '' && (
                <div className={styles.errorMessage}>
                  <Icon iconName="alert" className={styles.alert} />
                  {this.state.error}
                </div>
              )}
              <div className={styles.subOptions}>
                <Checkbox
                  label={'Stay signed in'}
                  size="small"
                  isChecked={persistence}
                  onChange={password => this.setState({ persistence: !persistence })}
                />
                <div className={styles.forgotPass} onClick={() => this.forgotPassword()}>
                  Reset password
                </div>
              </div>
              <div className={styles.buttonbar}>
                <Button
                  theme="primary"
                  onClick={() => this.login()}
                  disabled={disableLogin}
                  title={loginHelpText}
                >
                  Login
                </Button>
              </div>
            </div>
          </div>
        )}

        {this.resetPasswordModal()}
        {this.resetCompleteModal()}
        {this.tooManyLoginAttempts()}
      </div>
    );
  }
}

Login.propTypes = {
  firebase: PropTypes.shape({
    login: PropTypes.func.isRequired,
  }),
  auth: PropTypes.object,
};
export default compose(
  firebaseConnect(),
  connect(({ firebase: { auth } }, props) => ({ auth })),
)(Login);
