import initialState from './initialState';
import * as types from './actionTypes';

export default (state = initialState, action) => {
  switch (action.type) {
  case types.UPDATE_NOTIFICATIONS:
    return {
      ...state,
      notifications: action.payload
    };
  case types.VIDEO_SIZE_UPDATE:
    return {
      ...state,
      videoSize: action.payload
    };
  case types.UPDATE_MICROPHONE:
    return {
      ...state,
      isMicrophoneActive: action.payload
    };
  case types.UPDATE_INPUTDEVICE_STATUS:
    return {
      ...state,
      inputDeviceStatus: action.payload
    };
  case types.UPDATE_INPUTDEVICE:
    return {
      ...state,
      inputDeviceId: action.payload
    };
  case types.UPDATE_OUTPUTDEVICE:
    return {
      ...state,
      outputDeviceId: action.payload
    };
  case types.UPDATE_FULLSCREEN:
    return {
      ...state,
      isFullScreen: action.payload
    };
  case types.UPDATE_DEBUGMODE:
    return {
      ...state,
      isDebugMode: action.payload
    };
  case types.UPDATE_PLAYING:
    return {
      ...state,
      isPlaying: action.payload
    };
  case types.UPDATE_PAUSE:
    return {
      ...state,
      isPaused: action.payload
    };
  case types.UPDATE_SKIP_BACK:
    return {
      ...state,
      isSkippingBack: action.payload
    };
  case types.UPDATE_DRAWER:
    return {
      ...state,
      drawerItem: action.payload
    };
  case types.UPDATE_SHELF_STATUS:
    return {
      ...state,
      isShelfActive: action.payload
    };
  case types.UPDATE_AUDIO_VOLUME:
    return {
      ...state,
      volumeLevel: action.payload
    };
  case types.RESETTING_UI:
    return {
      ...initialState
    };
  case types.RATE_MEDIASCAPE:
    return {
      ...state
    };
  default:
    return state;
  }
};
