// rootReducer
import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import ui from './uiReducer/reducers';
import timer from './timerReducer/reducers';
export default combineReducers({
  ui,
  timer,
  firebase: firebaseReducer,
  // firestore: firestoreReducer
});

//   routing: routerReducer,
