import React, { Component } from 'react';
import classnames from 'classnames';
import Button from './Button';
import styles from './DropButton.module.scss';

// type Props = {
//   children?: React$Element<any> | Array<React$Element<any>>,
//   className?: string,
//   disabled?: boolean,
//   raised?: boolean,
//   wide?: boolean,
//   href?: string,
//   inverse?: boolean,
//   onClick?: Function,
//   size?: 'small' | 'normal' | 'large',
//   theme?: 'primary' | 'success' | 'warning' | 'danger' | 'dark' | 'link',
//   type?: string,
// };

class DropButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownActive: false,
    };
  }

  render() {
    const {
      children,
      // className,
      // disabled = false,
      label = 'button',
      // raised,
      wide = false,
      inverse = false,
      size = 'normal',
      theme = 'primary',
      // onClick,
      // ...others
    } = this.props;

    return (
      <div className={classnames(styles.buttonGroup, { [styles.wide]: wide })} onMouseLeave={() => this.setState({ isDropdownActive: false })}>
        <Button
          className={classnames(styles.dropdownToggle, styles.dropdownToggleSplit)}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          theme={theme}
          size={size}
          wide={wide}
          inverse={inverse}
          onClick={e => {
            e.stopPropagation();
            this.setState({ isDropdownActive: !this.state.isDropdownActive });
          }}
        >
          {label} <i className={classnames('fa', 'fa-angle-down')} aria-hidden="true" />
        </Button>
        <div className={classnames(styles.dropdownMenu, { [styles.active]: this.state.isDropdownActive })}>
          {children.map((item, index) => (
            <div key={index} className={styles.dropdownItem} {...item.props} />
          ))}
        </div>
      </div>
    );
  }
}

export default DropButton;
