import initialState from './initialState';
import * as types from './actionTypes';

export default (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_ACTIVE:
      return {
        ...state,
        isActive: action.payload,
      };
    case types.RESET_ACTIVE:
      return {
        ...state,
        activeDuration: action.payload,
        isActive: false,
      };
    case types.CHANGE_FULL_TIME:
      return {
        ...state,
        fullDuration: action.payload,
        activeDuration: action.payload,
      };
    case types.UPDATE_ACTIVE_TIME:
      return {
        ...state,
        activeDuration: action.payload,
      };
    case types.CREATE_CUSTOM_TIMER:
      return {
        ...state,
        fullDuration: action.payload,
      };
    case types.UPDATE_FIRST_RUN:
      return {
        ...state,
        firstRun: action.payload,
      };

    default:
      return state;
  }
};
