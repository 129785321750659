const bookDescLength = 300;
const uidLength = 20;
const loginAttemptLimit = 5;
const adminUserType = 'admin';

class PPConstants {
  static get bookDescLimit() {
    return bookDescLength;
  }

  static get uidLimit() {
    return uidLength;
  }

  static get adminUserType() {
    return adminUserType;
  }

  static get loginAttemptLimit() {
    return loginAttemptLimit;
  }

  static get loginMessage() {
    const loginMessage = {
      'auth/wrong-password': {
        message: "Email and password don't match.",
        type: 'password',
      },
      'auth/user-not-found': {
        message:
          'We couldn’t find that account. Please try another email address or request an invite.',
        type: 'account',
      },
      'auth/invalid-email': {
        message: 'Email address entered seems to be invalid.',
        type: 'account',
      },
      'auth/too-many-requests': {
        message:
          'You’ve made too many unsuccessful login attempts. If you need help, you can contact us or reset your password.',
        type: 'account',
      },
    };
    return loginMessage;
  }

  // see firebase: studio/features. That's the actual source of truth
  static get mediaTypes() {
    const mediaTypes = {
      BOOK: { name: 'book', label: 'Book' },
      VIDEO: { name: 'corpus_video', label: 'Corpus Video (deprecated)' },
      BRANCHINGVIDEO: { name: 'scene_video', label: 'Video' },
    };
    return mediaTypes;
  }

  static get copyrightTypes() {
    const crTypes = {
      PICTUREBOOK: { name: 'picturebook', label: 'picture book', media: 'print' },
      POETRY: { name: 'poetry', label: 'poetry', media: 'print' },
      VIDEO: { name: 'video', label: 'video', media: 'video' },
      PODPOPRIGINALS: { name: 'PodpopOriginals', label: 'Podpop originals', media: 'all' },
      PUBLICDOMAIN: { name: 'publicDomain', label: 'public domain', media: 'all' },
      CUSTOM: { name: 'custom', label: 'custom', media: 'all' },
    };
    return crTypes;
  }

  static get bookTypes() {
    let bookTypeArr = [];
    Object.keys(this.copyrightTypes).forEach(key => {
      if (this.copyrightTypes[key].media && this.copyrightTypes[key].media !== 'video')
        bookTypeArr.push({
          id: this.copyrightTypes[key].name,
          label: this.copyrightTypes[key].label,
        });
    });
    return bookTypeArr;
  }

  static get videoTypes() {
    let videoTypeArr = [];
    Object.keys(this.copyrightTypes).forEach(key => {
      if (this.copyrightTypes[key].media && this.copyrightTypes[key].media !== 'print')
        videoTypeArr.push({
          id: this.copyrightTypes[key].name,
          label: this.copyrightTypes[key].label,
        });
    });
    return videoTypeArr;
  }

  static get userTypes() {
    const userTypeObj = [
      { id: 'admin', label: 'Podpop Admin', color: '#1aaf4f' },
      { id: 'publisher', label: 'Publishing Partner', color: '#eb5e46' },
      { id: 'composer', label: 'Composer', color: '#2b8eaa' },
      { id: 'reviewer', label: 'Reviewer', color: '#3f3f3f' },
      { id: 'partner', label: 'Partner', color: '#fbaf5f' },
      { id: 'student', label: 'Student', color: '#000' },
      { id: 'user', label: 'End User', color: '#222' },
    ];
    return userTypeObj;
  }

  static get userSearchOptions() {
    const userSearchObj = [
      { id: 'email', label: 'email', color: '#222' },
      { id: 'type', label: 'User Type', color: '#222' },
      { id: 'first_name', label: 'First Name', color: '#222' },
      { id: 'last_name', label: 'Last Name', color: '#222' },
    ];
    return userSearchObj;
  }

  static get bookStatus() {
    const bookStatusObj = [
      { id: 'new', label: 'New Book', color: '#FF69B4' },
      { id: 'ondeck', label: 'On Deck', color: '#87a2c7' },
      { id: 'assigned', label: 'Assigned', color: '#092234' },
      { id: 'inprogress', label: 'In Progress', color: '#2b8eaa' },
      { id: 'readyforreview', label: 'Ready for Review', color: '#f2930c' },
      { id: 'torevise', label: 'To Revise', color: '#e1e1e1' },
      {
        id: 'externalapproval',
        label: 'External Approval Needed',
        color: '#cc060c',
      },
      { id: 'approved', label: 'Approved', color: '#1aaf4f' },
      { id: 'inreview', label: 'Soundscape Uploaded', color: '#ff2e00' },
      { id: 'published', label: 'Published', color: '#1c95b8' },
      { id: 'error', label: 'Needs Attention', color: '#bf360c' },
      { id: 'onhold', label: 'On Hold', color: '#3f3f3f' },
      { id: 'blacklisted', label: 'Blacklisted', color: '#222' },
    ];
    return bookStatusObj;
  }

  static get soundscapeStatus() {
    const soundscapeStatusObj = [
      { id: 'new', label: 'New Soundscape', color: '#FF69B4' },
      { id: 'ondeck', label: 'On Deck', color: '#87a2c7' },
      { id: 'assigned', label: 'Assigned', color: '#092234' },
      { id: 'inprogress', label: 'In Progress', color: '#2b8eaa' },
      { id: 'readyforreview', label: 'Ready for Review', color: '#f2930c' },
      { id: 'torevise', label: 'To Revise', color: '#e1e1e1' },
      {
        id: 'externalapproval',
        label: 'External Approval Needed',
        color: '#cc060c',
      },
      { id: 'approved', label: 'Approved', color: '#1aaf4f' },
      { id: 'inreview', label: 'Soundscape Uploaded', color: '#ff2e00' },
      { id: 'published', label: 'Published', color: '#1c95b8' },
      { id: 'error', label: 'Needs Attention', color: '#bf360c' },
      { id: 'onhold', label: 'On Hold', color: '#3f3f3f' },
    ];
    return soundscapeStatusObj;
  }

  static get hardware() {
    const appleModelObj = {
      iPhone1_1: { id: 'iPhone1,1', label: 'iPhone' },
      iPhone1_2: { id: 'iPhone1,2', label: 'iPhone 3G' },
      iPhone2_1: { id: 'iPhone2,1', label: 'iPhone 3GS' },
      iPhone3_1: { id: 'iPhone3,1', label: 'iPhone 4' },
      iPhone3_2: { id: 'iPhone3,2', label: 'iPhone 4' },
      iPhone3_3: { id: 'iPhone3,3', label: 'iPhone 4' },
      iPhone4_1: { id: 'iPhone4,1', label: 'iPhone 4S' },
      iPhone5_1: { id: 'iPhone5,1', label: 'iPhone 5' },
      iPhone5_2: { id: 'iPhone5,2', label: 'iPhone 5' },
      iPhone5_3: { id: 'iPhone5,3', label: 'iPhone 5c' },
      iPhone5_4: { id: 'iPhone5,4', label: 'iPhone 5c' },
      iPhone6_1: { id: 'iPhone6,1', label: 'iPhone 5s' },
      iPhone6_2: { id: 'iPhone6,2', label: 'iPhone 5s' },
      iPhone7_2: { id: 'iPhone7,2', label: 'iPhone 6' },
      iPhone7_1: { id: 'iPhone7,1', label: 'iPhone 6 Plus' },
      iPhone8_1: { id: 'iPhone8,1', label: 'iPhone 6s' },
      iPhone8_2: { id: 'iPhone8,2', label: 'iPhone 6s Plus' },
      iPhone8_4: { id: 'iPhone8,4', label: 'iPhone SE' },
      iPhone9_1: { id: 'iPhone9,1', label: 'iPhone 7' },
      iPhone9_3: { id: 'iPhone9,3', label: 'iPhone 7' },
      iPhone9_2: { id: 'iPhone9,2', label: 'iPhone 7 Plus' },
      iPhone9_4: { id: 'iPhone9,4', label: 'iPhone 7 Plus' },
      iPhone10_1: { id: 'iPhone10,1', label: 'iPhone 8' },
      iPhone10_4: { id: 'iPhone10,4', label: 'iPhone 8' },
      iPhone10_2: { id: 'iPhone10,2', label: 'iPhone 8 Plus' },
      iPhone10_5: { id: 'iPhone10,5', label: 'iPhone 8 Plus' },
      iPhone10_3: { id: 'iPhone10,3', label: 'iPhone X' },
      iPhone10_6: { id: 'iPhone10,6', label: 'iPhone X' },
      iPad1_1: { id: 'iPad1,1', label: 'iPad' },
      iPad2_1: { id: 'iPad2,1', label: 'iPad 2' },
      iPad2_2: { id: 'iPad2,2', label: 'iPad 2' },
      iPad2_3: { id: 'iPad2,3', label: 'iPad 2' },
      iPad2_4: { id: 'iPad2,4', label: 'iPad 2}' },
      iPad3_1: { id: 'iPad3,1', label: 'iPad (3rd generation)' },
      iPad3_2: { id: 'iPad3,2', label: 'iPad (3rd generation)' },
      iPad3_3: { id: 'iPad3,3', label: 'iPad (3rd generation)' },
      iPad3_4: { id: 'iPad3,4', label: 'iPad (4th generation)' },
      iPad3_5: { id: 'iPad3,5', label: 'iPad (4th generation)' },
      iPad3_6: { id: 'iPad3,6', label: 'iPad (4th generation)' },
      iPad4_1: { id: 'iPad4,1', label: 'iPad Air' },
      iPad4_2: { id: 'iPad4,2', label: 'iPad Air' },
      iPad4_3: { id: 'iPad4,3', label: 'iPad Air' },
      iPad5_3: { id: 'iPad5,3', label: 'iPad Air 2' },
      iPad5_4: { id: 'iPad5,4', label: 'iPad Air 2' },
      iPad6_7: { id: 'iPad6,7', label: 'iPad Pro (12.9-inch)' },
      iPad6_8: { id: 'iPad6,8', label: 'iPad Pro (12.9-inch)' },
      iPad6_3: { id: 'iPad6,3', label: 'iPad Pro (9.7-inch)' },
      iPad6_4: { id: 'iPad6,4', label: 'iPad Pro (9.7-inch)' },
      iPad6_11: { id: 'iPad6,11', label: 'iPad (5th generation)' },
      iPad6_12: { id: 'iPad6,12', label: 'iPad (5th generation)' },
      iPad7_1: { id: 'iPad7,1', label: 'iPad Pro (12.9-inch, 2nd generation)' },
      iPad7_2: { id: 'iPad7,2', label: 'iPad Pro (12.9-inch, 2nd generation)' },
      iPad7_3: { id: 'iPad7,3', label: 'iPad Pro (10.5-inch)' },
      iPad7_4: { id: 'iPad7,4', label: 'iPad Pro (10.5-inch)' },
      iPad2_5: { id: 'iPad2,5', label: 'iPad mini' },
      iPad2_6: { id: 'iPad2,6', label: 'iPad mini' },
      iPad2_7: { id: 'iPad2,7', label: 'iPad mini' },
      iPad4_4: { id: 'iPad4,4', label: 'iPad mini 2' },
      iPad4_5: { id: 'iPad4,5', label: 'iPad mini 2' },
      iPad4_6: { id: 'iPad4,6', label: 'iPad mini 2' },
      iPad4_7: { id: 'iPad4,7', label: 'iPad mini 3' },
      iPad4_8: { id: 'iPad4,8', label: 'iPad mini 3' },
      iPad4_9: { id: 'iPad4,9', label: 'iPad mini 3' },
      iPad5_1: { id: 'iPad5,1', label: 'iPad mini 4' },
      iPad5_2: { id: 'iPad5,2', label: 'iPad mini 4' },
      iPod1_1: { id: 'iPod1,1', label: 'iPod touch' },
      iPod2_1: { id: 'iPod2,1', label: 'iPod touch (2nd generation)' },
      iPod3_1: { id: 'iPod3,1', label: 'iPod touch (3rd generation)' },
      iPod4_1: { id: 'iPod4,1', label: 'iPod touch (4th generation)' },
      iPod5_1: { id: 'iPod5,1', label: 'iPod touch (5th generation)' },
      iPod7_1: { id: 'iPod7,1', label: 'iPod touch (6th generation)' },
      unknown: { id: 'unknown', label: 'unknown' },
    };
    return appleModelObj;
  }

  static get priorityLevels() {
    const priorityLevelsObj = [
      { id: 'normal', label: 'Normal', color: '#1aaf4f' },
      { id: 'low', label: 'Low', color: '#fbaf5f' },
      { id: 'high', label: 'High', color: '#b14736' },
    ];
    return priorityLevelsObj;
  }
}

export default PPConstants;

export const cueTypeObj = {
  AUDIO: 'audio',
  VIDEO: 'video',
  TEXT: 'text',
  JSON: 'json',
};
